a {
    text-decoration: none;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-visible,
.MuiDataGrid-cell:focus-within

/* .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-visible,
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-cell:focus-visible,
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-cell:focus-within  */
    {
    outline: none !important;
}

/* .css-1x5wgv-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1x5wgv-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-visible,
.css-1x5wgv-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-1x5wgv-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-1x5wgv-MuiDataGrid-root .MuiDataGrid-cell:focus-visible,
.css-1x5wgv-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
} */
